<template>
  <div>
    <v-text-field
      v-model="referenceSearch"
      prepend-icon="fal fa-books-medical fa-sm"
      label="PMID / DOI Code"
      dense
      clearable
    >
      <template #prepend>
        <v-icon size="20">
          fal fa-books-medical
        </v-icon>
      </template>
      <template #append-outer>
        <v-dialog
          v-model="newReferenceDialog"
          width="350"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              x-small
              :disabled="referenceSearch=='' || referenceSearch == null"
              v-on="on"
              @click="getReference"
            >
              <v-icon small>
                fal fa-plus-circle
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Add reference</v-card-title>
            <v-card-text class="pt-2 pb-0">
              <reference-form
                ref="referenceForm"
                info
                :code.sync="referenceSearch"
                :code-type.sync="newReference.code_type"
                :loading-code.sync="referenceLoading"
                :readonly.sync="referenceExist"
                @loading="newReferenceLoading = $event"
                @update-reference="newReference = $event"
                @reference-created="() => {
                  $root.$emit('add-references')
                }"
              />
              <v-col cols="12">
                {{ (referenceExist == false) ? "This reference does not exist in the databases. Do you want to create it?" : newReference.title }}
              </v-col>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="newReferenceDialog = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                text
                :loading="newReferenceLoading"
                :disabled="referenceLoading"
                @click="addReference"
              >
                {{ (referenceExist == false) ? 'Create' : 'Add' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-text-field>

    <v-card
      v-for="(item, index) in references"
      :key="item.id"
      class="mx-auto mb-2"
      outlined
      dense
    >
      <v-card-text>
        <v-row
          class="mb-1"
          no-gutters
        >
          <v-col>
            <span class="font-weight-black text-uppercase">
              {{ item.code_type + ": " +item.code }}
            </span>
          </v-col>

          <v-col md="1">
            <v-btn
              icon
              x-small
              @click="references.splice( index, 1 )"
            >
              <v-icon
                key="fa-link-slash"
                color="primary"
                xdark
                small
              >
                fal fa-link-slash
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div v-html="item.title" />
        <a
          :href="item.url"
          target="_blank"
          style="text-decoration:none"
        >
          {{ item.url }}
        </a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
    ReferenceForm: () => import("@/components/reference/ReferenceForm.vue"),
  },
  props: {
    references: {
        type: Array,
        default: () => ([]),
    },
  },
  data() {
    return {
      newReferenceDialog: false,
      newReferenceLoading: false,
      referenceSearch: "",
      referenceLoading: false,
      newReference: {
        title: "",
        code_type: "PMID",
      },
      referenceExist: true,
    };
  },
  watch: {
    /* references (){
        this.referenceName = this.code;
    }, */
  },
  mounted() {
    this.$root.$on("add-references", () => {
      this.addReferences();
    });
  },
  methods: {
    getReference() {
      this.referenceLoading = true;
      this.axios
        .get("admin/references/bycode", {
          params: {
            code: this.referenceSearch,
          },
        })
        .then((response) => {
          this.referenceExist = true;
          if (response.data) {
            this.newReference = response.data;
          } else {
            this.referenceExist = false;
          }
          this.referenceLoading = false;
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    addReferences() {
      this.references.push(this.newReference);
      this.referenceExist = true;
      this.newReferenceDialog = false;
      this.loading = false;
      this.referenceSearch = "";
      this.newReference = {
        title: "",
        code_type: "PMID",
      };
    },
    addReference() {
      if (this.referenceExist == false) {
        this.$refs.referenceForm.createReference();
      } else {
        this.addReferences();
      }
    },
  },
};
</script>
